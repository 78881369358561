import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { IAuth } from '../../../server/build/interfaces/account';
import { getDocument, getLocalStorage, getWindow } from './app.module';
import { NotificationComponent } from './components/notification/notification.component';
import { DarkmodeService } from './services/darkmode.service';
import { NavigationService } from './services/navigation.service';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  initialized = false;

  constructor(
    private darkmodeService: DarkmodeService,
    public navigation: NavigationService,
    private router: Router,
    private notification: NotificationComponent,
    private themeService: ThemeService
  ) {
    this.navigation.startSaveHistory();
    this.init();
  }

  async init() {
    this.getDarkmode();
    this.getTheme();
    this.initialized = true;
  }

  getDarkmode() {
    const darkmode = getLocalStorage()?.getItem('darkmode');
    this.darkmodeService.setDarkmode(darkmode);
  }

  getTheme() {
    const t = getLocalStorage()?.getItem('theme');
    this.themeService.setTheme(t);
  }
}
