import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { MarkdownModule } from 'ngx-markdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotificationComponent } from './components/notification/notification.component';
import { OverlayLoadingComponent } from './components/overlay-loading/overlay-loading.component';

import { TransferStateInterceptor } from './interceptors/transfer-state.interceptor';
import { LayoutComponent } from './layouts/layout/layout.component';
import { HttpModule } from './modules/http.module';
import { ScriptService } from './scripts/script.service';

export const getLocalStorage = () =>
  typeof window !== 'undefined' ? window.localStorage : null;

export const getWindow = () => (typeof window !== 'undefined' ? window : null);

export const getDocument = () => (typeof document !== 'undefined' ? document : null);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    IonicModule.forRoot({mode: 'md'}),
    AppRoutingModule,
    NotificationComponent,
    ModalComponent,
    LayoutComponent,
    MarkdownModule.forRoot(),
    HttpClientModule,
    OverlayLoadingComponent,
    HttpModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: 'WINDOW', useFactory: getWindow },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferStateInterceptor,
      multi: true,
    },
    ScriptService,
    NotificationComponent,
    OverlayLoadingComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
