import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  // {
  //   path: '',
  //   loadComponent: () =>
  //     import('./pages/custom-page/custom-page.page').then(
  //       (m) => m.CustomPagePage
  //     ),
  // },
  {
    // path: '',
    // loadChildren: () =>
    //   import('./pages/index/index-routing.module').then(
    //     (m) => m.IndexPageRoutingModule
    //   ),
    path: '',
    loadComponent: () =>
      import('./pages/index/index.page').then((m) => m.IndexPage),
  },
  {
    path: 'support',
    loadComponent: () =>
      import('./pages/support/support.page').then((m) => m.SupportPage),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
