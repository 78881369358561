import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SplitScreenComponent } from 'src/app/components/split-screen/split-screen.component';
import { EnvironmentInjector } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalComponent,
    RouterModule,
    SplitScreenComponent,
  ],
  selector: 'app-layout',
  styleUrls: ['./layout.component.scss'],
  template: `
    <ion-router-outlet
      [environmentInjector]="environmentInjector"
    ></ion-router-outlet>
  `,
})
export class LayoutComponent implements OnInit {
  menuOpen = false;

  constructor(
    public environmentInjector: EnvironmentInjector
  ) {
  }

  ngOnInit() {}

}
