import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { OverlayLoadingState } from 'src/app/states/overlay-loading.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, DragDropModule],
  selector: 'app-overlay-loading',
  styleUrls: ['./overlay-loading.component.scss'],
  template: `
    <div class="full-screen" *ngIf="message !== undefined">
      <div class="overlay"></div>
      <div class="loading-screen">
        {{ message }}
      </div>
    </div>
  `,
})
export class OverlayLoadingComponent implements OnInit {
  message;
  str;

  constructor(
    private overlayState: OverlayLoadingState,
  ) {
    overlayState.overlayLoading$.subscribe((m) => (this.message = m.message));
  }

  ngOnInit() {}

  showOverlayLoading(message?: string) {
    this.overlayState.setOverlayLoading({
      message: message ?? this.str('loading_'),
    });
  }

  hideOverlayLoading() {
    this.overlayState.setOverlayLoading({ message: undefined });
  }
}
