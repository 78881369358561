import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IModal, IModalButton, ModalState } from 'src/app/states/modal.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-modal',
  styleUrls: ['./modal.component.scss'],
  template: `
    <div class="modal" *ngIf="modal.show">
      <div class="modal-overlay"></div>
      <ion-card class="modal-container">
        <div class="ion-text-right">
          <div class="modal-close-btn" (click)="close()">&times;</div>
        </div>
        <div class="modal-content">
          <h2 class="ion-text-center">{{ modal.title }}</h2>
          <hr />
          <div class="ion-text-center">{{ modal.body }}</div>
          <div class="modal-buttons">
            <div *ngFor="let button of modal.buttons">
              <ion-button
                class="modal-button"
                [color]="button.color"
                (click)="button.onClick ? button.onClick() : close()"
                ><ion-icon [name]="button.icon" *ngIf="button.icon"></ion-icon>
                {{ button.text }}</ion-button
              >
            </div>
          </div>
        </div>
      </ion-card>
    </div>
  `,
})
export class ModalComponent implements OnInit {
  modal: IModal;

  constructor(private modalState: ModalState) {
    modalState.modal$.subscribe((m) => (this.modal = m));
  }

  ngOnInit() {}

  showModal(title?: string, body?: string, buttons?: IModalButton[]) {
    this.modalState.showModal(title, body, buttons);
  }

  close() {
    this.modalState.hideModal();
  }
}
