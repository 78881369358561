import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class NotificationState {
  notification = new BehaviorSubject<INotification>({});
  notification$ = this.notification.asObservable();

  constructor() {}

  setNotification(newNotification: INotification) {
    this.notification.next(newNotification);
  }
}

export interface INotification {
  message?: string;
  type?: 'success' | 'danger' | 'info' | 'warning';
}
