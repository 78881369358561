/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationComponent } from 'src/app/components/notification/notification.component';
import { serverConfig } from 'src/environments/environment';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class HttpModule {
  str;

  subs = new Subscription();

  constructor(
    private notification: NotificationComponent,
    private router: Router,
    private http: HttpClient
  ) {}

  async httpGet<T>(url: string, params?: any): Promise<T> | undefined {
    try {
      const res = await this.http
        .get<T>(this.getUrl(url), {
          headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line quote-props
          },
          params,
        })
        .pipe(catchError(this.catchError.bind(this)))
        .toPromise();

      return res;
    } catch (err) {
      this.notification.errorNotification(err);
    }
    return undefined;
  }

  async httpPost<T>(url: string, body: any): Promise<T> | undefined {
    try {
      const res = await this.http
        .post<T>(this.getUrl(url), body, {
          headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line quote-props
          },
        })
        .pipe(catchError(this.catchError.bind(this)))
        .toPromise();
      return res;
    } catch (err) {
      this.notification.errorNotification(err);
    }
    return undefined;
  }

  httpPostStream(url: string, body: any): Observable<any> | undefined {
    try {
      const res = this.http
        .post(this.getUrl(url), body, {
          headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line quote-props
          },
          responseType: 'text',
          reportProgress: true,
          observe: 'events',
        })
        .pipe(catchError(this.catchError.bind(this)));

      return res;
    } catch (err) {
      this.notification.errorNotification(err);
    }
    return undefined;
  }

  async httpPut<T>(url: string, body: any): Promise<T> | undefined {
    try {
      const res = await this.http
        .put<T>(this.getUrl(url), body, {
          headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line quote-props
          },
        })
        .pipe(catchError(this.catchError.bind(this)))
        .toPromise();
      return res;
    } catch (err) {
      this.notification.errorNotification(err);
    }
    return undefined;
  }

  async httpDelete<T>(url: string, params?: any): Promise<T> | undefined {
    try {
      const res = await this.http
        .delete<T>(this.getUrl(url), {
          headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line quote-props
          },
          params,
        })
        .pipe(catchError(this.catchError.bind(this)))
        .toPromise();

      return res;
    } catch (err) {
      this.notification.errorNotification(err);
    }
    return undefined;
  }

  async uploadFile<T>(
    url: string,
    file: File,
    progress?: (prog: number) => void
  ) {
    try {
      if (file) {
        const form = new FormData();
        form.append('file', file, file.name);

        const res = await this.http
          .post<T>(this.getUrl(url), form, {
            headers: {
              // 'Content-Type': 'application/json',
              // eslint-disable-next-line quote-props
            },
            reportProgress: true,
            observe: 'events',
          })
          .pipe(
            tap((event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                const prog = Math.round((event.loaded / event.total) * 100);
                if (progress) {
                  progress(prog);
                }
              }
            }),
            catchError(this.catchError.bind(this))
          )
          .toPromise();
        return res;
      }
    } catch (err) {
      this.notification.errorNotification(err);
    }
  }

  catchError(err: HttpErrorResponse) {
    let errorMsg = err.error.error;
    if (err.status === 401) {
      this.router.navigateByUrl('/login');
      errorMsg = this.str('unauthorizedAccess');
    } else if (err.status === 412) {
      this.router.navigateByUrl('/confirm-email');
      errorMsg = this.str('pleaseConfirmEmail');
    } else {
      errorMsg = errorMsg ?? this.str('somethingWentWrong');
    }

    return throwError(new Error(errorMsg));
  }

  getUrl(url: string) {
    const u =
      serverConfig.serverUrl +
      '/api/' +
      (url.startsWith('/') ? url.replace('/', '') : url);
    console.log(u);
    return (
      serverConfig.serverUrl +
      '/api/' +
      (url.startsWith('/') ? url.replace('/', '') : url)
    );
  }
}

export const getApiURL = (url: string) => (
    serverConfig.serverUrl +
    '/api/' +
    (url.startsWith('/') ? url.replace('/', '') : url)
  );
