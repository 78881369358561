import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  INotification,
  NotificationState,
} from 'src/app/states/notification.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-notification',
  styleUrls: ['./notification.component.scss'],
  template: `
    <ion-card
      class="Notification"
      color="{{
        notification.type === 'info' ? 'secondary' : notification.type
      }}"
      *ngIf="notification.message"
    >
      <div class="notification-container">
        <ion-label class="notification-message">
          {{ notification.message }}
        </ion-label>
        <div class="notification-btn ion-text-center" (click)="close()">
          &times;
        </div>
      </div>
    </ion-card>
  `,
})
export class NotificationComponent implements OnInit {
  notification: INotification;
  timeout;

  constructor(private notificationState: NotificationState) {
    this.notificationState.notification$.subscribe((m) => {
      if (m.message) {
        this.autoClose();
      }
      this.notification = m;
    });
  }
  ngOnInit() {}

  autoClose() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.close();
    }, 3000);
  }

  close() {
    this.notificationState.setNotification({
      type: 'success',
    });
  }

  successNotification(message: string) {
    this.notificationState.setNotification({
      message,
      type: 'success',
    });
  }

  errorNotification(message: string) {
    this.notificationState.setNotification({
      message,
      type: 'danger',
    });
  }

  infoNotification(message: string) {
    this.notificationState.setNotification({
      message,
      type: 'info',
    });
  }

  warningNotification(message: string) {
    this.notificationState.setNotification({
      message,
      type: 'warning',
    });
  }
}
