import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalState {
  modal = new BehaviorSubject<IModal>({});
  modal$ = this.modal.asObservable();

  constructor() {}

  showModal(title?: string, body?: string, buttons?: IModalButton[]) {
    this.modal.next({
      show: true,
      title,
      body,
      buttons,
    });
  }

  hideModal() {
    this.modal.next({
      show: false,
    });
  }
}

export interface IModal {
  show?: boolean;
  title?: string | HTMLElement;
  body?: string | HTMLElement;
  buttons?: IModalButton[];
}

export interface IModalButton {
  icon?: string;
  text?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'dark'
    | 'medium'
    | 'light';
  onClick?: (param?: any) => void;
}
