import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getLocalStorage } from '../app.module';

@Injectable({
  providedIn: 'root',
})
export class DarkmodeService {
  darkmode = new BehaviorSubject<string | undefined>(undefined);
  darkmode$ = this.darkmode.asObservable();

  constructor() {
    const selectedTheme = getLocalStorage()?.getItem('darkmode');
    if (selectedTheme) {
      this.setDarkmode(selectedTheme);
    } else {
      this.setDarkmode(undefined);
    }
  }

  getDarkmode() {
    return getLocalStorage()?.getItem('darkmode');
  }

  setDarkmode(t?: string) {
    this.darkmode.next(t);
    getLocalStorage()?.setItem('darkmode', t);
    if(t && typeof window !== 'undefined') {
      if(t === 'dark') {
        document.querySelector('body').classList.add('dark');
      } else {
        document.querySelector('body').classList.remove('dark');
      }
    }
  }
}
