import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getDocument, getLocalStorage } from '../app.module';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  theme = new BehaviorSubject<string | undefined>(undefined);
  theme$ = this.theme.asObservable();

  constructor() {
    const selectedTheme = getLocalStorage()?.getItem('theme');
    if (selectedTheme) {
      this.setTheme(selectedTheme);
    } else {
      this.setTheme(undefined);
    }
  }

  getTheme() {
    return getLocalStorage()?.getItem('theme') ?? 'night-ocean';
  }

  setTheme(t?: string) {
    const oldTheme = this.theme.value;
    this.theme.next(t);
    getLocalStorage()?.setItem('theme', t);

    if(oldTheme) {
      getDocument()?.querySelector('body').classList.remove('theme-' + oldTheme);
    }

    if (t && typeof window !== 'undefined') {
      getDocument()?.querySelector('body').classList.add('theme-' + t);
    } else {
      getDocument()?.querySelector('body').classList.add('theme-default');
    }
  }
}
