import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {}

  startSaveHistory() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  getHistory() {
    return this.history;
  }

  goBack(defaultBackHref?: string) {
    this.history.pop();

    if (this.history.length > 0) {
      this.location.back();
    } else {
      if(defaultBackHref) {
        this.router.navigateByUrl(defaultBackHref);
      } else {
        this.router.navigateByUrl('/');
      }
    }
  }

  public getPreviousUrl() {
    if (this.history.length > 0) {
      return this.history[this.history.length - 2];
    }

    return '';
  }
}
