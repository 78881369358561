import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayLoadingState {
  overlayLoading = new BehaviorSubject<IOverlayLoading>({});
  overlayLoading$ = this.overlayLoading.asObservable();

  constructor() {}

  setOverlayLoading(data: IOverlayLoading) {
    this.overlayLoading.next(data);
  }
}

export interface IOverlayLoading {
  message?: string;
}
